/**
 *
 * Created by vsuthar on 2019-04-08 File UserMenu
 * Project: App Portal ©PaloAlto Networks
 */
import { CortexBase, html, customDefine } from '@pan/cortex-component'
import '@pan/cortex-component/src/avtar'
import UserMenuStyle from '../scss/userMenu.scss'

export default class UserMenu extends CortexBase {
  static get is() {
    return 'cortex-user-menu'
  }

  static get properties() {
    return {
      selectedAccount: { type: Number },
      userData: { type: Object },
      supportedAccounts: { type: Array },
      view_profile: { type: String },
      allowHubLinkEvent: { type: Boolean },
      isVertical: { type: Boolean },
      theme: { type: String },
      collapsedWidth: { type: Number } // set collapsed width for app bar
    }
  }

  static get styles() {
    return [UserMenuStyle]
  }

  constructor() {
    super()
    this.supportedAccounts = []
    this.view_profile = null
    this.allowHubLinkEvent = undefined
    this.isVertical = false
    this.collapsedWidth = 65
  }

  firstUpdated() {
    this.theme = this.isVertical ? '-dark' : ''
  }

  createMenu(menu) {
    return menu.map((menuItem, idx) => {
      const { menuLabel, children } = menuItem
      let childMenu = null
      if (children && Array.isArray(children)) {
        childMenu = html`<div class="cortex-drop-down-child-menu">${this.createMenu(children, idx)}</div>`
      }
      return html`
        <a href="#">${menuLabel}</a>
         ${childMenu}
      `
    })
  }

  _onUserListItem(e) {
    const { detail } = e
    if (detail && detail.hasOwnProperty('type') && detail.type === 'user-account') {
      const accountSelected = new CustomEvent('cortex-account-selected', {
        detail: detail,
        bubbles: true,
        composed: true,
      })
      this.dispatchEvent(accountSelected)
    }
  }

  _handleSignOut() {
    const signOutEvt = new CustomEvent('cortex-sign-out', { bubbles: true, composed: true })
    this.dispatchEvent(signOutEvt)
  }

  showMenu(isMenuOpen, isCollapsed) {
    const el = this.shadowRoot.getElementById('_usermenu-id')
    const isVertical = !!this.isVertical
    el.style.display = isMenuOpen ? 'block' : 'none'
    // Same user menu serving both for horizontal and vertical hence we need to have different styling
    if (el) {
      if (isVertical) {
        el.style.left = isCollapsed ? `${this.collapsedWidth + 5}px` : '255px'
        if (isMenuOpen) {
          el.classList.add('user-menu-vertical-position')
        }
        else {
          el.classList.remove('user-menu-vertical-position')
        }
      }
      else {
        if (isMenuOpen) {
          el.classList.add('user-menu-top-position')
        }
        else {
          el.classList.remove('user-menu-top-position')
        }
      }
    }
  }

  getSupportAccountId() {
    const { supportedAccounts = [], selectedAccount } = this
    const items = []
    if (supportedAccounts.length) {
      supportedAccounts.forEach((account) => {
        if (account.name) {
          const { accountid } = account
          return items.push({
            label: account.name,
            key: accountid,
            selected: accountid.toString() === selectedAccount.toString(),
            is_federal: account.is_federal,
          })
        }
      })
      return html`
        <div class=${`account-area ${this.theme}`}>
          <cortex-list-box
           theme=${this.theme} title="Accounts"
           type="user-account"
           @cortex-list-item=${this._onUserListItem}
           items=${JSON.stringify(items)}>
          </cortex-list-box>
        </div>
    `
    }
    return null

  }

  _handleProfileClick(e) {
    if (this.allowHubLinkEvent) {
      e.preventDefault()
      const eve = new CustomEvent('on-hub-link-clicked', { detail: { cmp: 'viewprofile' } })
      this.dispatchEvent(eve)
    }
  }

  getUserNameProfile() {
    const { userData = {}, view_profile } = this
    const { userName } = userData
    return html`
      <div class="vbox">
        <div class="username">${userName}</div>
        ${view_profile ? html`<a class=${`view-profile ${this.theme}`} href=${view_profile} @click="${this._handleProfileClick}" >View Profile</a>` : null}
      </div>
    `
  }

  getHorizontalTheme() {
    return html`
    <div class=${`profile-area${this.theme}`}>
        <div>
            ${this.isVertical ? html`<div class="vertical-username-container">${this.getUserNameProfile()}</div>` : html`<cortex-avatar>${this.getUserNameProfile()}</cortex-avatar>`}
        </div>
     </div>
    `
  }

  render() {
    return html`
      <div id="_usermenu-id" class=${`vbox user-menu${this.theme}`}>
        ${this.getHorizontalTheme()}
        ${this.getSupportAccountId()}
        <div class=${`signout-area ${this.theme}`} @click=${this._handleSignOut}>
          <a>Sign Out</a>
        </div>
      </div>
    `
  }
}
customDefine(UserMenu.is, UserMenu)
