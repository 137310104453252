/**
 * Created by vsuthar on 11/2/20
 * Project: App Portal ©Palo Alto Networks
 */

import { CortexBase, html, customDefine } from '@pan/cortex-component'
import UserMenu from './UserMenu'

export default class VerticalUserMenu extends CortexBase {
  static get is() {
    return 'cortex-vertical-user-menu'
  }
  render () {
    return html `
      <div>

       </div>
    `
  }
}

customDefine(VerticalUserMenu.is, VerticalUserMenu)
