/**
 *
 * Created by vsuthar on 2019-04-08 File Avatar
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement, html } from 'lit-element'
import AvatarStyle from './scss/avatar.scss'
import { customDefine } from '../util'

const AVATAR_SVG = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M8 0a4 4 0 110 8 4 4 0 010-8zm0 1a3 3 0 100 6 3 3 0 000-6zM3 14h7.5a.5.5 0 110 1h-8a.5.5 0 01-.5-.5V11c0-1 1-2 2-2h8c1.2 0 2 1 2 2v3.5a.5.5 0 11-1 0V11c0-.5-.4-1-1-1H4c-.5 0-1 .5-1 1v3z'/%3E%3C/svg%3E"

export default class Avatar extends LitElement {
  static get is() {
    return 'cortex-avatar'
  }
  static get styles() {
    return [AvatarStyle]
  }
  render() {
    return (
      html `
        <div class="align-items-center">
          <div class="avatar" >
             <img src="${AVATAR_SVG}" >
          </div>
          <slot></slot>
        </div>
      `
    )
  }
}
customDefine(Avatar.is, Avatar)
