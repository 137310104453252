/**
 *
 * Created by vsuthar on 2019-03-07 File AppBar.js
 * Project: App Portal ©PaloAlto Networks
 */
import { CortexBase, Icons, customDefine } from '@pan/cortex-component'
import { html } from 'lit-element'
import { appBarStyle } from './AppBar-css'
import AppBarStyleScss from './../scss/appbar.scss'
import VerticalBarStyle from './../scss/verticalAppBar.scss'
import '@pan/cortex-app-switcher'
import './DropDownMenu'
import './UserMenu'
import './VerticalUserMenu'
import { Drawer } from '@pan/cortex-component'
import { CARET_DOWN } from '@pan/cortex-component/src/icons' // eslint-disable-line no-unused-vars
class AppBar extends CortexBase {
  static get is() {
    return 'cortex-app-bar'
  }

  static get properties() {
    return {
      appTitle: { type: String }, // app title
      selectedAccount: { type: Number }, // selected account, user can get this from SAML
      appBarLogo: { type: String }, // app bar logo, User needs to provide SVG with base 64 encoded
      collapsedLogo: { type: String }, // collapsed logo (small logo)
      notonorafter: { type: String }, //  REQUIRED notonorafter, User can get this value from SAML,
      accesstoken: { type: String }, // REQUIRED accesstoken, User can get this value from SAML
      supportedAccounts: { type: Array }, // REQUIRED supportedAccounts, User can get this value from SAML
      actionItems: { type: Object },
      userData: { type: Object }, // REQUIRED json object combination of user data
      showAppSwitcher: { type: Boolean }, // show hide app-switcher
      pinnedMenu: { type: Array }, // DO NOT USE
      pingcert: { type: String }, // REQUIRED User can get this from SAML
      appId: { type: String }, // appId use for create different URL for entitlements
      allowHubLinkEvent: { type: Boolean }, // allow user to capture event and pass event handler when user click on the hub link
      _isMenuOpen: { type: Boolean }, // Not used
      _showSideBar: { type: Boolean }, // Not used
      _entitlements: { type: Object }, // User can set _entitlements if they want to render app-switcher
      _isLoading: { type: Boolean }, // _isLoading, flag will render loading mask in app-switcher
      _letmeswitch: { type: Boolean }, //_letmeswitch, flag will allow user to switch between account and also only render pass account id
      hubLink: { type: String }, // app-portal hub link
      _baseURL: { type: String }, // base URL to special case
      _noAppsMessage: { type: String }, // message when entitlements failed
      _hideUserMenu: { type: Boolean }, // added for redlock
      _view_profile: { type: String }, // come from entitlements
      _roles: { type: Object },
      selectedTenant: { type: String }, // Highlight selected tenant from list
      _selectedAccountIdx: { type: Number }, // selected account index used by app-portal
      _isVertical: { type: Boolean }, // is app bar is vertical or horizontal
      isCollapsed: { type: Boolean }, // determine if left bar collapsed or not
      left: { type: Number }, // determine how much pixel from left
      appSwitcherLabel: { type: String }, // app-switcher label
      letMeCollapse: { type: String }, // it will allow to see collapsible icon, we need to handle as string
      showUserMenu: { type: Boolean }, // show user menu or not
      manual: { type: Boolean },  // set entitlements manually
      collapsedWidth: { type: Number }, // set collapsed width for app bar
      allowLinkCallBackApps: { type: Array },
      showFeedbackButton: { type: Boolean }, // show the feedback button or not
      showAnnouncementButton: { type: Boolean } // show the announcement button or not
    }
  }


  static get styles() {
    return [appBarStyle, AppBarStyleScss, VerticalBarStyle]
  }


  constructor() {
    super()
    this.accesstoken = ''
    this.selectedAccount = ''
    this._isMenuOpen = false
    this.supportedAccounts = []
    this._showSideBar = true
    this.pinnedMenu = []
    this._isLoading = false
    this.appId = 'appportal'
    this._entitlements = {}
    this._letmeswitch = false
    this.handleLogoClicked = null
    this.hasBeenMount = function() {}
    this.linkHandler = null
    this.allowHubLinkEvent = undefined
    this._baseURL = undefined
    this._noAppsMessage = undefined
    this.hubLink = 'https://apps.paloaltonetworks.com/apps'
    this._hideUserMenu = false
    this._view_profile = ''
    this._roles = {}
    this._selectedAccountIdx = 0
    this._isVertical = false
    this.isCollapsed = false
    this.appSwitcherLabel = 'All Applications'
    this.collapsed = function() {}
    this.showUserMenu = false
    this.letMeCollapse = 'yes'
    this.manual = false
    this.collapsedWidth = 65
    this.allowLinkCallBackApps = []
    this.callbackWhenSwitch = () => {}
    this.showFeedbackButton = false
  }

  firstUpdated(_changedProperties) {
    this._isVertical ? this.left = 250 : this.left = NaN
    this.appSwitcherLabel = this._isVertical ? this.appSwitcherLabel : null
    if (this.showUserMenu) {
      document.body.addEventListener('mouseup', this.showHideUserMenu.bind(this, false))
    }
    this._findAccountBasedId(this.selectedAccount, null)
    this.hasBeenMount()
  }

  setAppSwitcherLoading(isLoading) {
    const appSwitcher = this.shadowRoot.querySelector('cortex-app-switcher')
    if (appSwitcher) {
      appSwitcher.setLoading(isLoading)
    }
  }

  _handleActionClick(actionName) {
    super.fireCortexEvent({ cmp: 'AppBar', type: actionName })
  }

  getActionItems() {
    const { actionItems } = this
    if (actionItems && actionItems.actionData) {
      const { actionData } = actionItems
      return actionData.map(actionItem => {
        const { img, size, actionName } = actionItem
        /* eslint-disable lit/no-template-bind */
        return html`
            <button @click=${() => {
    this._handleActionClick(actionName)
  }}><img src=${img} height=${size} width=${size} /></button>
          `
        /* eslint-enable lit/no-template-bind */
      })
    }
  }

  showHideUserMenu(isMenuOpen, e) {
    if (!this.showUserMenu) {
      return
    }
    /**
     * This means user clicked on app switcher do not close app switcher
     */
    if (e && e.target.id === 'cortex-app-switcher-id') return
    /**
     * This means user clicked on user menu so do not close user menu
     */
    if (e && e.path && e.path.length) {
      const paths = e.path
      const userMenu = paths.find(path => path.id === '_usermenu-id' || path.id === '_cortex-vertical-bar-id') || {}
      if (userMenu.id === '_usermenu-id') {
        return
      }
    }
    const userMenu = this.shadowRoot.querySelector('cortex-user-menu')
    if (userMenu) {
      const { isCollapsed } = this
      userMenu.showMenu(isMenuOpen, isCollapsed)
    }
  }

  _onUserMenuClick(e) {
    this._isMenuOpen = !this._isMenuOpen
    this.showHideUserMenu(true, e)
  }

  getUserData() {
    const { userData } = this
    const { userName, companyName } = userData
    const divider = html`<div class="cortex-username-divider"></div>`
    return html`${divider} <button title=${userName} class="cortex-user-name" @click=${this._onUserMenuClick} >
            <div class="hbox align-items-center"><div class="user-name">${userName}</div> <img src=${Icons.CHEVRON_DOWN} /></div>
            <div title='${companyName}' class="corp-name">${companyName}</div>
            </button>`

  }

  roleMappingReducer(roleMapping, role) {
    if (role === 'Directory Sync Service') {
      roleMapping['Directory Sync'] = true
      return roleMapping
    }
    if (role === 'Logging Service') {
      roleMapping['Log Forwarding'] = true
    }
    role ? roleMapping[role] = true : null
    return roleMapping
  }

  _convertRoles(roles = {}) {
    return Array.isArray(roles) ? roles.reduce(this.roleMappingReducer, {}) : roles
  }

  _findAccountBasedId(accountId, e) {
    const { supportedAccounts } = this
    let indexCntr = -1
    const selectedAccount = supportedAccounts.find(item => {
      indexCntr = ++indexCntr
      if (item && item.hasOwnProperty('accountid') && item.hasOwnProperty('signature')) {
        const { accountid } = item
        if (accountId === accountid) {
          this._selectedAccountIdx = indexCntr
          return item
        }
      }
      return null
    })
    this._isMenuOpen = false
    this.showHideUserMenu(false, e)
  }

  _onAccountClicked(e) {
    const { _letmeswitch } = this
    const { detail } = e
    if (_letmeswitch && detail && detail.hasOwnProperty('type') && detail.type === 'user-account') {
      const { key } = detail
      if (key !== this.selectedAccount) {
        this.selectedAccount = key
        this._findAccountBasedId(key, e)
      }
    }
  }

  getUserMenu() {
    const { supportedAccounts = [], userData, selectedAccount, _letmeswitch, _entitlements, allowHubLinkEvent } = this
    let _supportAccounts = supportedAccounts.slice().sort((a, b) => {
      //APPORTAL-1881 sort case insensitive
      if (a.name && b.name) {
        const { name: aName } = a
        const { name: bName } = b
        if (aName.toLowerCase() < bName.toLowerCase()) return -1
        if (aName.toLowerCase() > bName.toLowerCase()) return 1
        return 0
      }
      return 0

    })
    if (_letmeswitch === false) {
      _supportAccounts = supportedAccounts.filter(account => {
        return account && account.accountid && account.accountid === selectedAccount
      })
    }
    const appPortal = _entitlements['App Portal']
    if (appPortal && appPortal['name'].toLowerCase() === 'app portal' && appPortal.hasOwnProperty('account_info')) {
      this._view_profile = `${appPortal.account_info}?selectedAccount=${this._selectedAccountIdx}`
    }
    if (allowHubLinkEvent) {
      return html`<cortex-user-menu
                    allowHubLinkEvent
                    collapsedWidth=${this.collapsedWidth}
                    ?isVertical=${this._isVertical}
                    @on-hub-link-clicked=${this.onHubLinkClicked}
                    view_profile=${this._view_profile}
                    selectedAccount=${selectedAccount}
                    @cortex-account-selected=${this._onAccountClicked}
                    userData=${JSON.stringify(userData)}
                    supportedAccounts=${JSON.stringify(_supportAccounts)}></cortex-user-menu>`
    }
    else {
      return html`<cortex-user-menu
                    view_profile=${this._view_profile}
                    collapsedWidth=${this.collapsedWidth}
                    ?isVertical=${this._isVertical}
                    selectedAccount=${selectedAccount}
                    @cortex-account-selected=${this._onAccountClicked}
                    userData=${JSON.stringify(userData)}
                    supportedAccounts=${JSON.stringify(_supportAccounts)}></cortex-user-menu>`
    }
  }


  _onMenuClosed() {
    this._showSideBar = !this._showSideBar
    const leftNav = this.shadowRoot.querySelector('cortex-slide-nav')
    if (leftNav) {
      leftNav.classList.toggle('hide-nav-bar')
      const navBarOpenClose = new CustomEvent('cortex-left-bar', {
        detail: { 'leftbar': this._showSideBar },
        bubbles: true,
        composed: true,
      })
      this.dispatchEvent(navBarOpenClose)
    }

  }

  openDrawer(obj) {
    const appSwitcher = this.shadowRoot.querySelector('cortex-app-switcher-button')
    if (appSwitcher) {
      appSwitcher.openDrawer(obj)
    }
  }

  /**
   * App switcher button
   * @returns {*}
   */

  getAppSwitcherBtn() {
    const { showAppSwitcher } = this
    if (showAppSwitcher) {
      return html`
        <div class="cortex-username-divider"></div>
        ${this.getAppSwitcher()}
      `
    }
  }

  /**
   * Hide and show button
   * @private
   */
  _overlayGotClicked(e) {
    if (this.showAppSwitcher) {
      this.openDrawer({ showAppDrawer: false })
    }
  }

  /**
   *
   * @returns {*}
   * based on allowHubLinkEvent=true|false we need to provide this props to
   * app-switcher. app-switcher emit event on-hub-link-clicked only if allowHubLinkEvent is true
   */


  getAppSwitcher() {
    const {
      hubLink,
      allowHubLinkEvent = undefined,
      _noAppsMessage = 'No App Found',
      selectedTenant,
      _selectedAccountIdx,
      selectedAccount,
      notonorafter,
      accesstoken,
      supportedAccounts,
      userData,
      pingcert,
      appId,
      _entitlements = {},
      _isVertical,
      isCollapsed,
      _baseURL,
      manual,
    } = this
    const appSwitcherLabel = this._isVertical ? 'All Applications' : null
    let left = NaN
    if (this._isVertical && !isCollapsed) {
      left = 250
    }
    else if (this._isVertical && isCollapsed) {
      left = this.collapsedWidth
    }
    else {
      left = NaN
    }
    if (allowHubLinkEvent) {
      return html`<cortex-app-switcher-button  id="cortex-app-switcher-id" _noAppsMessage=${_noAppsMessage}
                                 appSwitcherLabel=${_isVertical && !isCollapsed ? appSwitcherLabel : ''}
                                 left=${_isVertical ? left : NaN}
                                 _baseURL=${_baseURL ? _baseURL : ''}
                                 _entitlements=${JSON.stringify(_entitlements)}
                                 _selectedAccountIdx=${_selectedAccountIdx}
                                 allowHubLinkEvent=${allowHubLinkEvent}  hubLink=${hubLink}
                                 @on-hub-link-click=${this.onHubLinkClicked} @on-app-switch-event=${this.onAppLinkClicked}
                                 selectedAccount=${selectedAccount}
                                 notonorafter=${notonorafter}
                                 accesstoken=${accesstoken}
                                 supportedAccounts=${JSON.stringify(supportedAccounts)}
                                 userData=${JSON.stringify(userData)}
                                 pingcert=${pingcert}
                                 appId=${appId}
                                 ?manual=${manual} allowLinkCallBackApps=${JSON.stringify(this.allowLinkCallBackApps)}
                                 selectedTenant=${selectedTenant}></cortex-app-switcher-button>`
    }
    return html`<cortex-app-switcher-button id="cortex-app-switcher-id"  _noAppsMessage=${_noAppsMessage}
                                appSwitcherLabel=${_isVertical && !isCollapsed ? appSwitcherLabel : ''}
                                left=${_isVertical ? left : NaN}
                                _baseURL=${_baseURL ? _baseURL : ''}
                                @on-hub-link-clicked=${this.onHubLinkClicked}
                                            @on-app-switch-event=${this.onAppLinkClicked}
                                _entitlements=${JSON.stringify(_entitlements)}
                                _selectedAccountIdx=${_selectedAccountIdx}
                                selectedAccount=${selectedAccount}
                                hubLink=${hubLink}
                                notonorafter=${notonorafter}
                                accesstoken=${accesstoken}
                                supportedAccounts=${JSON.stringify(supportedAccounts)}
                                userData=${JSON.stringify(userData)}
                                pingcert=${pingcert}
                                appId=${appId}
                                ?manual=${manual} allowLinkCallBackApps=${JSON.stringify(this.allowLinkCallBackApps)}
                                selectedTenant=${selectedTenant}
                                 ></cortex-app-switcher-button>`
  }

  /**
   *
   * @returns {AppBar.appTitle}
   */
  getAppBarLogo() {
    const { appBarLogo, appTitle, collapsedLogo, isCollapsed } = this
    let logo = html`<img class="app-bar-logo" src=${appBarLogo}>`
    if (logo && !isCollapsed) {
      logo = html`<img class="app-bar-logo" src=${appBarLogo}>`
    }
    else if (collapsedLogo && isCollapsed) {
      logo = html`<img class="app-bar-logo" src=${collapsedLogo}>`
    }
    return appBarLogo ? logo : appTitle
  }

  /**
   *
   * @param e
   * handle click on the hub link. T
   * Note: This is only happen when allowHubLinkEvent = true default value for this is false
   */
  onHubLinkClicked(e) {
    const { detail } = e
    const { cmp } = detail || {}
    if (this.linkHandler) {
      this.showHideUserMenu(false, e)
      this.linkHandler(cmp, e)
    }
  }

  onAppLinkClicked(e) {
    this.callbackWhenSwitch(e.detail)
  }

  /**
   *
   * @param e
   * handle click on the logo container and provide call back through handler
   */
  onLogoClicked(e) {
    if (this.linkHandler) {
      this.linkHandler('logo', e)
    }
  }

  /**
   * When user click on the collapsible click then capture that event and provide callback
   * @param e
   */
  onCollapseBtnClick(e) {
    const { isCollapsed } = this
    this.isCollapsed = !isCollapsed
    if (!isCollapsed) {
      this.left = this.collapsedWidth
    }
    else {
      this.left = 250
    }
    this.collapsed(!isCollapsed)
  }

  openVerticalMenuBar(e) {
    this._isMenuOpen = !this._isMenuOpen
    const userMenu = this.shadowRoot.querySelector('cortex-user-menu')
    if (userMenu) {
      const { isCollapsed } = this
      userMenu.showMenu(true, isCollapsed)
    }
  }

  getVerticalAccSwitcher() {
    const { userName } = this.userData
    const { isCollapsed } = this
    const { _selectedAccountIdx = 0, supportedAccounts = [] } = this
    const acc = supportedAccounts[_selectedAccountIdx]
    const accName = (acc && acc.name) || ''
    return html
    `<button class=${`appbar-vertical-user ${isCollapsed ? '-collapsed' : ''}`} @click=${this.openVerticalMenuBar}>
         <span class="menu-icon"></span>
         <div class="vertical-user-container">
            <span class="vertical-user-name" title="${userName}">${userName}</span>
            <span class="vertical-acc-name" title="${accName}">${accName}</span>
            </div>
        </button>
       `
  }

  getFeedbackButton() {
    const { isCollapsed } = this
    return html
    `<button class=${`app-bar-vertical-feedback ${isCollapsed ? '-collapsed' : ''}`}>
        <span class="menu-icon"></span>
        <div class="vertical-user-container">
          <span>Give Feedback</span>
        </div>
      </button>
      `
  }

  getAnnouncementButton() {
    const { isCollapsed } = this
    return html
    `<button class=${`app-bar-vertical-announcemnt ${isCollapsed ? '-collapsed' : ''}`}>
        <span class="menu-icon"></span>
        <div class="vertical-user-container">
          <span class='announcement'>Announcements</span>
        </div>
      </button>
      `
  }

  render() {
    const { userData, _isVertical, showAppSwitcher, isCollapsed, showUserMenu, letMeCollapse, showFeedbackButton, showAnnouncementButton } = this
    if (_isVertical) {
      const openClose = isCollapsed ? 'close' : 'open'
      return html`
      <div id="_cortex-vertical-bar-id" class=${`cortex-app-bar-vertical ${openClose}`}>
         ${letMeCollapse.toLowerCase() === 'yes' ? html `<button class="caret-icon" @click=${this.onCollapseBtnClick} >
              <img src=${CARET_DOWN} />
          </button>` : null}
        <div class=${`cortex-brand-container ${_isVertical ? '-dark' : ''}`} @click=${this.onLogoClicked}>
          ${this.getAppBarLogo()}
        </div>
        <div class="cortex-vertical-nav"  @click=${this._overlayGotClicked}>
            <slot></slot>
        </div>
        ${showUserMenu ? this.getVerticalAccSwitcher() : null}
        ${showFeedbackButton ? this.getFeedbackButton() : null}
        ${showAnnouncementButton ? this.getAnnouncementButton() : null}
        ${showAppSwitcher ? html`<button class="vertical-app-switcher">
                                    ${this.getAppSwitcher()}
                                  </button>` : null}
        ${this.getUserMenu()}
       </div>
      `
    }
    return html`
      <div class="cortex-appbar-skelton">
        <div class="cortex-brand-container" @click=${this.onLogoClicked}>
          ${this.getAppBarLogo()}
        </div>
        <div class="cortex-appbar-navigation">
          <slot></slot>
        </div>
        <div class="cortex-app-bar-actionbar">
          ${this.actionItems && this.actionItems.hasOwnProperty('actionData') ? this.getActionItems() : null}
          ${userData && !this._hideUserMenu ? this.getUserData() : null}
          ${this.getUserMenu()}
          ${this.getAppSwitcherBtn()}
        </div>
      </div>
    `
  }
}

customDefine(AppBar.is, AppBar)
