/**
 *
 * Created by vsuthar on 2019-03-19 File AppSwitcher
 * Project: App Portal ©PaloAlto Networks
 */
import { CortexBase, customDefine, html, Icons } from '@pan/cortex-component'
import AppSwitcherStyle from '../scss/appSwitcher.scss'
import '@pan/cortex-component/src/drawer'
import '@pan/cortex-component/src/listitem'

class AppSwitcher extends CortexBase {
  static get styles() {
    return [AppSwitcherStyle]
  }

  static get properties() {
    return {
      entitlementList: { type: Object },
      isLoading: { type: Boolean },
      hubLink: { type: String },
      allowHubLinkEvent: { type: Boolean },
      _noAppsMessage: { type: String },
      selectedTenant: { type: String },
      _selectedAccountIdx: { type: Number },
      allowLinkCallBackApps: { type: Array }
    }
  }

  constructor() {
    super()
    this.entitlementList = {}
    this.isLoading = false
    this.allowHubLinkEvent = undefined
    this._noAppsMessage = 'No apps found'
    this.allowLinkCallBackApps = []
    this.otherResources = [
      {
        name: 'Customer Support',
        link: 'https://www.paloaltonetworks.com/company/contact-support'
      },
      {
        name: 'Live Community',
        link: 'https://live.paloaltonetworks.com/'
      },
      {
        name: 'Palo Alto Networks Home',
        link: 'https://www.paloaltonetworks.com/'
      }
    ]
  }

  showLoading(isLoading) {
    this.isLoading = isLoading
  }

  /**
   *
   * @param tenants
   * @returns {*|Array|...*[]|(TemplateResult | TemplateResult)[]}
   */

  createList(tenants = [], display_name, logo, app_id) {
    const { selectedTenant } = this
    return tenants.map(tenant => {
      const { tenant_id, tenant_instance_name, url, serial_number, tenant_display_name } = tenant
      /**
       * We need to match with serial_number also due to some of the application dont have tenant_id in their front-end
       * Example: RedLock
       * @type {boolean}
       */
      const isSelected = tenant_id === selectedTenant || serial_number === selectedTenant
      if (tenants.length === 1) {
        return html`
          ${this.createLinkButton(display_name, logo, url, isSelected, app_id)}
        `
      }
      return html`
        ${this.createLinkButton(tenant_display_name || tenant_instance_name || serial_number || tenant_id, logo, url, isSelected, app_id)}
      `
    })
  }

  /**
   *
   * @returns {TemplateResult | TemplateResult}
   * Simple divider for app switcher
   */
  getDivider() {
    return html`<div class='divider'></div>`
  }

  /**
   *
   * @returns {any[]}
   * get entitlements and set it
   */
  getEntitlements() {
    const { _noAppsMessage } = this
    const _entitleList = Object.keys(this.entitlementList)
    if (_noAppsMessage && _entitleList.length === 0 || (_entitleList.length === 1 && _entitleList.indexOf('App Portal') > -1)) {
      return html`
                <div class="no-apps-found">
                  ${_noAppsMessage}
                </div>
                `
    }
    return _entitleList.map(app => {
      const { name, filter_tenants = [], entitled, display_name, enabled, logo = Icons.HOLLOW, app_id } = this.entitlementList[app]
      if (entitled && filter_tenants.length && enabled) {
        if (filter_tenants.length > 1) {
          return html`
            <cortex-detail icon=${logo} summary=${display_name || name} count=${String(filter_tenants.length)}>
              <div class="tenants-list">
                ${this.createList(filter_tenants, display_name, logo, app_id)}
              </div>
            </cortex-detail>
          `
        }
        return html`
          <div class="just-instances">
            ${this.createList(filter_tenants, display_name, logo, app_id)}
          </div>
        `
      }
      else {
        return null
      }
    })
  }

  /**
   * Create links for app switcher
   * @param name
   * @param icon
   * @param link
   * @returns {TemplateResult | TemplateResult}
   */
  createLinkButton(name, icon, link, isSelected, app_id) {
    const allowLinkCallBackApps = this.allowLinkCallBackApps
    const iconImage = icon ? html`<img class="link-logo" src=${icon} />` : null
    if (allowLinkCallBackApps.includes(app_id) || allowLinkCallBackApps.includes('*')) {
      return html`
      <div class=${isSelected ? 'switcher-link other-resources-link tenant-selected' : 'switcher-link other-resources-link '}  @click=${this._appLinkClick.bind(this, app_id, link)}>
        ${iconImage}
        <a class='link' title=${`${name}`} target="_blank" href=${link}>${name}</a>
      </div>
    `
    } else {
      return html`
      <div class=${isSelected ? 'switcher-link other-resources-link tenant-selected' : 'switcher-link other-resources-link '}>
        ${iconImage}
        <a class='link' title=${`${name}`} href=${link}>${name}</a>
      </div>
    `
    }

  }

  /**
   * show loading indicator when entitlements is loading
   */
  getLoading() {
    if (this.isLoading) {
      return html`<cortex-loading isLoading></cortex-loading>`
    }
    else {
      return null
    }
  }

  /**
   * set Loading
   * @param isLoading
   */
  setLoading(isLoading) {
    this.isLoading = isLoading
  }

  _handleHubLinkEvent(e) {
    if (this.allowHubLinkEvent) {
      e.preventDefault()
      const eve = new CustomEvent('on-hub-link-clicked', { detail: { cmp: 'hublink' }, bubbles: true })
      this.dispatchEvent(eve)
    }
  }

  _appLinkClick(app_id,link, e) {
    if(this.allowLinkCallBackApps) {
      e.preventDefault()
      const eve = new CustomEvent('on-app-switch-event', { detail: { app_id, link }, bubbles: true })
      this.dispatchEvent(eve)
    }
  }

  /**
   *
   * @param name
   * @param icon
   * @param link
   * @returns {TemplateResult|TemplateResult}
   */

  createAppPortalLink(name, icon, link) {
    const { entitlementList,allowLinkCallBackApps } = this
    if (entitlementList.hasOwnProperty('App Portal')) {
      link = `${(entitlementList['App Portal'].url || link)}?selectedAccount=${this._selectedAccountIdx}`
    }

    const imageLink = 'https://storage.googleapis.com/download/storage/v1/b/pan-prod-app-assets/o/hub%2Flogo.svg?alt=media'
    const iconImage = html`<img class="hub-logo link-logo" src=${imageLink} alt='' />`
    /**
     * allowHubLinkEvent emit events and capture in appbar component
     */
    if (allowLinkCallBackApps.includes('HUB') || allowLinkCallBackApps.includes('*')) {

      return html`
      <div class="app-portal-link align-items-center" @click=${this._appLinkClick.bind(this, 'HUB', link)}>
        <a class='link align-items-center' title=${name} href=${link} @click=${this._handleHubLinkEvent} > ${icon ? iconImage : null} ${name}</a>
      </div>
      `
    }
    else {
      return html`
      <div class="app-portal-link align-items-center">
        <a class='link align-items-center' title=${name} href=${link} @click=${this._handleHubLinkEvent} > ${icon ? iconImage : null} ${name}</a>
      </div>
      `
    }
  }

  /**
   *
   * @returns {TemplateResult | TemplateResult}
   */
  render() {
    const { otherResources, hubLink } = this
    return html`
      <div class="cortex--app-switcher">
        <div>
          <div class="hub-link-container">
            ${this.createAppPortalLink('HUB', Icons.PANW_LOGO_BLUE, hubLink)}
          </div>
          ${this.getDivider()}
        </div>
        <div class="detail-container">
          ${this.getLoading()}
          ${this.getEntitlements()}
        </div>
        <div class="other-resources">
          ${this.getDivider()}
          <div class="title">Other Resources</div>
          ${otherResources.map((item) => {
      const { name, link } = item
      return this.createLinkButton(name, null, link)
    })}
        </div>
      </div>
    `
  }
}


customDefine('cortex-app-switcher', AppSwitcher)
