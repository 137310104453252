/**
 *
 * Created by vsuthar on 2019-03-13 File CortexBase
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement } from 'lit-element'

class CortexBase extends LitElement {
  static get properties() {
    return {
      rbac: { type: Object }
    }
  }
  constructor() {
    super()
    this.rbac = {}
  }

  fireCortexEvent(data) {
    const event = new CustomEvent('cortexEvent', {
      detail: {
        cortexActionType: data
      },
      bubbles: true,
      composed: true
    })
    this.dispatchEvent(event)
  }
}

export default CortexBase

