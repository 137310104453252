/**
 *
 * Created by vsuthar on 2019-04-10 File drawer
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement, html } from 'lit-element'
import { styleMap } from 'lit-html/directives/style-map'
import DrawerStyle from './scss/drawer.scss'
import DrawerRightStyle from './scss/drawer-right.scss'
import { customDefine } from '../util'
export default class Drawer extends LitElement {
  static get is() {
    return 'cortex-drawer'
  }
  static get styles() {
    return [DrawerStyle, DrawerRightStyle]
  }

  static get properties() {
    return {
      showAppDrawer: { type: Boolean },
      childClassName: { type: String },
      class: { type: String },
      left: { type: Number }
    }
  }

  constructor() {
    super()
    this.left = 0
  }

  _overlayClicked() {
    const overLayClick = new CustomEvent('_overlay-clicked')
    this.dispatchEvent(overLayClick)
  }

  hideDrawer() {
    this.showAppDrawer = false
    // this.requestUpdate()
    const drawerSlot = this.shadowRoot.querySelector('[part="toggle"]')
    if (this.left >= 0) {
      // drawerSlot.style.left = '-310px'
      drawerSlot.style.width = '0px'
    }

  }

  openDrawer() {
    this.showAppDrawer = true
    const drawerSlot = this.shadowRoot.querySelector('[part="toggle"]')
    if (drawerSlot.classList.contains('drawer-menu-show')) {
      drawerSlot.classList.remove('drawer-menu-show')
      drawerSlot.classList.add('drawer-menu-show')
      if (this.left >= 0) {
        // drawerSlot.style.left = this.left ? this.left + 'px' : '0px'
        drawerSlot.style.width = '310px'
      }

    }
    else {
      drawerSlot.classList.add('drawer-menu-show')
      if (this.left >= 0) {
        // drawerSlot.style.left = this.left ? this.left + 'px' : '0px'
        drawerSlot.style.width = '310px'
      }

    }
    this.requestUpdate()
  }


  render() {
    const containerClass = this.showAppDrawer ? 'drawer-container-zIndex' : ''
    const className = this.showAppDrawer ? 'drawer-slide drawer-container-zIndex' : ''
    const showOverlay = this.showAppDrawer ? 'drawer-overlay' : 'hide-overlay'
    const { left } = this
    const drawerStyle = left >= 0 ? { left: `${left}px` } : { right: `0px` }
    return (
      html `
        <div class=${`drawer-container ${containerClass}`}>
          <div style=${styleMap(drawerStyle)} part="toggle"  class=${`${className} cortex-drawer`}>
            <slot></slot>
          </div>
          <div style=${styleMap(drawerStyle)} class=${showOverlay} @click="${this._overlayClicked}">
          </div>
        </div>
      `
    )
  }
}

customDefine(Drawer.is, Drawer)
