
import { css } from 'lit-element' // eslint-disable-line

export const appBarStyle = css `
  .cortex-appbar-skelton{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index:1030;
    transition: box-shadow .3s ease;
    font-family: "Lato", sans-serif;
    display: flex;
    height: 50px;
    background-image:var(--cortex-app-bar-bg-image,linear-gradient(to right, #0A3B53 120px, #021017 400px));
    background-color:var(--cortex-app-bar-bg-color, transparent);
    box-shadow: var(--cortex-app-bar-box-shadow, 0px 2px 4px rgba(0, 0, 0, 0.5));
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .cortex-app-bar-vertical {
    font-size: var(--cortex-vertical-menu-font-size, 14px);
  }
  .cortex-brand-container{
    margin: var(--cortex-app-bar-brand-container, 16px 0 16px 16px);
    min-width: 100px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    line-height: 50px;
    white-space: nowrap;
    font-size: 15px;
    color:white;
    font-weight:bold;
    text-transform:uppercase;
    cursor: pointer;
  }
  .cortex-appbar-navigation{
    flex:1;
    display:flex;
    height:100%;
  }
  .cortex-app-bar-actionbar{
    height:100%;
    align-items:center;
    display: flex;
    justify-content: flex-end;
  }
  .cortex-app-bar-actionbar button {
    background: transparent;
    transition: all 0.25s ease;
    border:none;
  }
  .cortex-app-bar-actionbar button:hover{
    cursor:pointer;
  }
  .cortex-app-bar-actionbar button:focus{
    outline:none;
  }
  .cortex-user-name{
    display:flex;
    flex-direction:column;
    color:white;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 170px;
    padding: 0 12px;
  }

  .cortex-user-name > div {
    width: 152px;
  }

  .user-name {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

 .cortex-user-name > div img{
    height: 16px;
    width: 16px;
    margin-left: 8px;
  }

  .cortex-username-divider:after{
    content: "";
    background-color: var(--cortex-app-bar-divider, #49535b);
    height: var(--cortex-app-bar-divider-height, 16px);
    width: var(--cortex-app-bar-divider-width, 1px);
    opacity: var(--cortex-app-bar-divider-opacity, 1);
    display: inline-block;
  }
  .corp-name {
    font-size: 12px;
    width: 150px;
    text-align: left;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space:nowrap;
    opacity: .6
  }
  .cortex-app-bar-actionbar button{
    transition: all 0.25s ease;
    opacity: .8;
  }
  .cortex-app-bar-actionbar button:hover{
    opacity: 1;
  }
  .app-switcher-icon {
    margin: 0 16px !important;
    padding: 0 !important;
    opacity: .65 !important;
  }
  .app-switcher-icon:hover {
     opacity: 1 !important;
  }
`
