
/**
 *
 * Created by vsuthar on 2019-04-08 File index.js
 * Project: App Portal ©PaloAlto Networks
 */

export const customDefine = (customElementName, wc) => {
  if (window.customElements && !customElements.get(customElementName)) {
    customElements.define(customElementName, wc)
  }
}
