/**
 *
 * Created by vsuthar on 2019-04-08 File ListBox
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement, html } from 'lit-element'
import ListBoxStyle from './scss/listItem.scss'
import '../listitem'

import { customDefine } from '../util'

export default class ListBox extends LitElement {
  /**
   *
   * @returns {string}
   */
  static get is() {
    return 'cortex-list-box'
  }

  /**
   *
   * @returns {{type: {type: StringConstructor}, title: {type: StringConstructor}, items: {type: ArrayConstructor}}}
   */
  static get properties() {
    return {
      items: { type: Array },
      type: { type: String },
      title: { type: String },
      theme: { type: String },
    }
  }

  constructor() {
    super()
    this.theme = ''
  }


  /**
   *
   * @returns {*[]}
   */
  static get styles() {
    return [ListBoxStyle]
  }

  /**
   *
   * @returns {*}
   */
  getListItem() {
    const { items, type } = this
    if (items && items.length) {
      return items.map((item) => {
        const { label, key, selected, is_federal } = item
        if (label) {
          /* eslint-disable lit/no-template-bind */
          return html `<cortex-list-item  theme=${this.theme} title=${label} ?is_federal=${is_federal} selectedClass=${selected ? 'cortex-list-item-selected' : ''}
                        @click=${this.handleListItem.bind(this, key, type)}>${label}</cortex-list-item>`
        }
        else {
          return html `<cortex-list-item theme=${this.theme} title=${label} selectedClass=${selected ? 'cortex-list-item-selected' : ''}
                        @click=${this.handleListItem.bind(this, key, type)}>${item}</cortex-list-item>`
        }
        /* eslint-disable lit/no-template-bind */
      })
    }
  }


  handleListItem(key, type, e) {
    const newCortexEvent = new CustomEvent('cortex-list-item', { detail: { key, type }, bubbles: true, composed: true })
    this.dispatchEvent(newCortexEvent)
  }

  render() {
    return html `
      <div class=${`cortex-list ${this.theme}`}>
        <h2>${this.title}</h2>
        <li>
          ${this.getListItem()}
        </li>
      </div>
    `
  }
}

customDefine(ListBox.is, ListBox)
