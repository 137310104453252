/**
 *
 * Created by vsuthar on 2019-04-12 File Details
 * Project: App Portal ©PaloAlto Networks
 */

import { LitElement, html } from 'lit-element'
import DetailStyles from './sass/detail.scss'

import { customDefine } from '../util'

export default class Details extends LitElement {
  constructor() {
    super()
    this.expanded = false
    this.icon = undefined
    this.count = 0
  }

  static get is() {
    return 'cortex-detail'
  }

  static get styles() {
    return [DetailStyles]
  }

  static get properties() {
    return {
      expanded: {
        type: Boolean,
        reflect: true
      },
      summary: {
        type: String
      },
      count: {
        type: String
      },
      icon: {
        type: String
      }
    }

  }

  get collapsible() {
    return this.shadowRoot.querySelector('[part="content"]')
  }

  get focusElement() {
    return this.shadowRoot.querySelector('button')
  }

  firstUpdated() {
    // prevent Shift + Tab on content from host blur
    this.collapsible.addEventListener('keydown', e => {
      if (e.shiftKey && e.keyCode === 9) {
        e.stopPropagation()
      }
    })

    super.firstUpdated()
  }

  updated(props) {
    super.updated(props)

    if (props.has('expanded')) {
      this.dispatchEvent(
        new CustomEvent('expanded-changed', {
          detail: { value: this.expanded }
        })
      )
    }
  }

  _onToggleClick() {
    this.expanded = !this.expanded
  }
  render() {
    const { count } = this
    const className = this.expanded ? '' : 'hide-content'
    return html `
      <div role="heading" class="cortex--detail">
        <button type="button"
         part="header"
          @click="${this._onToggleClick}"
           aria-expanded="${this.expanded ? 'true' : 'false'}" >
          <span part="toggle"></span>
          <div class="hbox detail-header-container">
            <img class="detail-icon" src=${this.icon || 'error.src'} /><span part="summary">${this.summary}</span>
          </div>
          ${count ? html `<span class="count">${count}</span>` : null}
          
        </button>
        <div part="content"
           class="${className}"
           area-hidden="${this.expanded ? 'false' : 'true'}" >
           <slot></slot>
        </div>
      </div>
    `
  }
}

customDefine(Details.is, Details)
