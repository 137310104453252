/**
 *
 * Created by vsuthar on 2019-03-11 File DropDownMenu
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement, html } from 'lit-element'
import { customDefine } from '@pan/cortex-component'
import { dropDownMenuStyle } from './DropDownMenu-css'

class DropDownMenu extends LitElement {
  static get properties() {
    return {
      menuData: { type: Array }
    }
  }

  static get styles() {
    return [dropDownMenuStyle]
  }

  createMenu(menu, level) {

    return menu.map((menuItem, idx) => {
      const { menuLabel, children } = menuItem
      let childMenu = null
      if (children && Array.isArray(children)) {
        childMenu = html`<div class="cortex-drop-down-child-menu">${this.createMenu(children, idx)}</div>`
      }
      return html`
        <a href="#">${menuLabel}</a>
         ${childMenu}
      `
    })
  }

  render() {
    return html`
      <li class="cortex-drop-down">
        <button ><slot></slot></button>
        <div class="cortex-drop-down-content">
          ${this.menuData && this.menuData.length ? this.createMenu(this.menuData) : null}
        </div>
      </li>
    `
  }
}

customDefine('cortex-drop-down', DropDownMenu)
