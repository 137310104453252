/**
 *
 * Created by vsuthar on 2019-03-12 File MenuItem
 * Project: App Portal ©PaloAlto Networks
 */
import { html } from 'lit-element'
import CortexBase from '../../CortexBase'
import { customDefine } from '../util'
import MenuItemStyle from './menuItem.scss'

export default class MenuItem extends CortexBase {
  static get is() {
    return 'cortex-menu-item'
  }
  static get properties() {
    return {
      icon: { type: String },
      type: { type: String },
      label: { type: String },
      size: { type: String },
      className: { type: String },
      selected: { type: Boolean },
      iconOnly: { type: Boolean }
    }
  }

  static get styles() {
    return [MenuItemStyle]
  }

  getClassNames() {
    if (this.selected) {
      return `cortex-nav-menuItem cortex-nav-menuItem-selected ${this.className}`
    }
    return `cortex-nav-menuItem ${this.className}`

  }

  _handleMenuClick() {
    super.fireCortexEvent({ cmp: 'Nav', type: this.type })
  }

  createMenuItem() {
    return html`
      <li class=${this.getClassNames()} @click=${this._handleMenuClick}>
        ${this.icon ? html`<div class="logo"><img height=${this.size} width=${this.size} src=${this.icon}></div>` : null}
        ${!this.iconOnly ? html `<div class="label"><slot></slot></div>` : null}
      </li>
    `


  }

  render() {
    return html`${this.createMenuItem()}`
  }
}

customDefine(MenuItem.is, MenuItem)
