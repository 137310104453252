import { CortexBase, customDefine, html, Icons } from '@pan/cortex-component'
import './AppSwitcher'
import { Drawer } from '@pan/cortex-component' // eslint-disable-line no-unused-vars
import SwitcherStyle from '../scss/app-switcher-btn.scss'

const URL_REGEX = /^https?:\/\//i

class Main extends CortexBase {
  static get is() {
    return 'cortex-app-switcher-button'
  }
  static get styles() {
    return [SwitcherStyle]
  }
  static get properties() {
    return {
      selectedAccount: { type: Number }, // selected account, user can get this from SAML
      notonorafter: { type: String }, //  REQUIRED notonorafter, User can get this value from SAML,
      accesstoken: { type: String }, // REQUIRED accesstoken, User can get this value from SAML
      supportedAccounts: { type: Array }, // REQUIRED supportedAccounts, User can get this value from SAML
      userData: { type: Object }, // REQUIRED json object combination of user data
      showAppSwitcher: { type: Boolean }, // show hide app-switcher
      pinnedMenu: { type: Array }, // DO NOT USE
      pingcert: { type: String }, // REQUIRED User can get this from SAML
      appId: { type: String }, // appId use for create different URL for entitlements
      allowHubLinkEvent: { type: Boolean }, // allow user to capture event and pass event handler when user click on the hub link
      _isMenuOpen: { type: Boolean }, // Not used
      _showSideBar: { type: Boolean }, // Not used
      _entitlements: { type: Object }, // User can set _entitlements if they want to render app-switcher
      _isLoading: { type: Boolean }, // _isLoading, flag will render loading mask in app-switcher
      _letmeswitch: { type: Boolean }, //_letmeswitch, flag will allow user to switch between account and also only render pass account id
      hubLink: { type: String }, // app-portal hub link
      _baseURL: { type: String }, // base URL to special case
      _noAppsMessage: { type: String }, // message when entitlements failed
      _hideUserMenu: { type: Boolean }, // added for redlock
      _view_profile: { type: String }, // come from entitlements
      _roles: { type: Object },
      selectedTenant: { type: String }, // Highlight selected tenant from list
      _selectedAccountIdx: { type: Number }, // selected account index used by app-portal
      left: { type: Number },
      appSwitcherLabel: { type: String },
      manual: { type: Boolean },
      showAppDrawer: { type: Boolean },
      allowLinkCallBackApps: { type: Array }
    }
  }

  constructor() {
    super()
    this.appId = 'appportal'
    this._entitlements = {}
    this.manual = false
    this.showAppDrawer = false
    this.allowLinkCallBack = false
    this.allowLinkCallBackApps = []
  }


  firstUpdated(_changedProperties) {
    this._findAccountBasedId(this.selectedAccount)
  }

  // updated(_changedProperties){
  //   console.log('coming in update ok')
  //   console.log(_changedProperties)
  //   const drawer = this.shadowRoot.querySelector('cortex-drawer')
  //   drawer.left = this.left
  // }

  _findAccountBasedId(accountId) {
    const { supportedAccounts = [] } = this
    let indexCntr = -1
    const selectedAccount = supportedAccounts.find(item => {
      indexCntr = ++indexCntr
      if (item && item.hasOwnProperty('accountid') && item.hasOwnProperty('signature')) {
        const { accountid } = item
        if (accountId === +accountid) {
          this._selectedAccountIdx = indexCntr
          return item
        }
      }
      this._selectedAccountIdx = indexCntr
      return null
    })
    if (selectedAccount) {
      const { accountid, signature } = selectedAccount
      this._getEntitlementsData({ accountId: accountid, signature, roles: this._roles })
    }
  }

  _getEntitlementsData({ accountId = 245, signature }) {
    const { accesstoken, userData, notonorafter, pingcert, appId } = this
    if (userData && accesstoken && userData.hasOwnProperty('email') && notonorafter && pingcert) {
      const { email } = userData
      const data = {
        'accesstoken': accesstoken,
        'pingcert': pingcert,
        'user_email': email,
        'support_account_id': accountId,
        'signature': signature,
        'notonorafter': notonorafter,
        'logo': true
      }

      const url = this._baseURL ? `${this._baseURL}/actionservice/${appId}/api/v1/entitlements` : `/actionservice/${appId}/api/v1/entitlements`
      this.setAppSwitcherLoading(true)
      const { _baseURL } = this
      const entitlementList = fetch(url, {
        method: 'POST',
        credentials: _baseURL ? 'include' : 'same-origin',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((res) => {
        this.setAppSwitcherLoading(false)
        return res.json()
      }).catch((e) => {
        this.setAppSwitcherLoading(false)
        this._noAppsMessage = 'No apps found'
      })
      entitlementList.then(res => {
        if (res && res.hasOwnProperty('entitlements')) {
          const { entitlements } = res
          this._entitlements = entitlements
        }
      }).catch(error => {
        throw new Error(error)
      })
    }
    else if (!userData) {
      // eslint-disable-next-line no-console
      console.warn('You have forgot one of the following, accesstoken, notonorafter, pingcert or userData')
    }
  }
  setAppSwitcherLoading(isLoading) {
    const appSwitcher = this.shadowRoot.querySelector('cortex-app-switcher')
    if (appSwitcher) {
      appSwitcher.setLoading(isLoading)
    }
  }
  _onAppSwitcherClick() {
    this.showAppDrawer = !this.showAppDrawer
  }

  _overlayGotClicked() {
    this.showAppDrawer = false
  }

  openDrawer(obj) {
    const { showAppDrawer } = obj
    this.showAppDrawer = showAppDrawer
  }


  static doFilter(entitlements) {
    if (typeof entitlements === 'string') {
      entitlements = JSON.parse(entitlements)
    }
    for (const eachEntitled in entitlements) {
      const app = entitlements[eachEntitled]
      const { tenants, no_app_tile_ui, app_url, app_id } = app
      if (app_url && URL_REGEX.test(app_url)) {
        app.filter_tenants = [{
          tenant_id: app_id,
          url: app_url,
        }]
      }
      else if (!no_app_tile_ui && tenants && tenants.length) {
        app.filter_tenants = tenants.filter(tenant => {
          const { instance_status = '', url, no_app_tile_ui: noAppTitleUi } = tenant
          if (url && URL_REGEX.test(url) && instance_status.toLowerCase() === 'running' && !noAppTitleUi) {
            return tenant
          }
          else return null
        })
      }
    }
    return JSON.stringify(entitlements)
  }

  onHubLinkClicked(e) {
    const eve = new CustomEvent('on-hub-link-click', { detail: { cmp: 'hublink' }, bubbles: true })
    this.dispatchEvent(eve)
  }
  onAppLinkClicked(e) {
    const eve = new CustomEvent('on-app-switch-event', { detail: e.detail , bubbles: true })
    this.dispatchEvent(eve)
  }

  getAppSwitcher() {
    const {
      hubLink,
      allowHubLinkEvent = undefined,
      _noAppsMessage = 'No App Found',
      selectedTenant,
      _selectedAccountIdx,
      left,
      allowLinkCallBackApps
    } = this
    return html`<cortex-drawer @_overlay-clicked=${this._overlayGotClicked} left=${left} ?showAppDrawer=${this.showAppDrawer}>
                  ${allowHubLinkEvent ?
        html`<cortex-app-switcher _noAppsMessage=${_noAppsMessage}
                                  allowLinkCallBackApps=${JSON.stringify(allowLinkCallBackApps)}
                                  @on-app-switch-event=${this.onAppLinkClicked}
                              entitlementList=${Main.doFilter(this._entitlements)}
                              @on-hub-link-clicked=${this.onHubLinkClicked}
                              _selectedAccountIdx=${_selectedAccountIdx}
                              allowHubLinkEvent  hubLink=${hubLink}
                              selectedTenant=${selectedTenant}></cortex-app-switcher>` :
        html`<cortex-app-switcher hubLink=${hubLink}
                                  allowLinkCallBackApps=${JSON.stringify(allowLinkCallBackApps)}
                                  @on-app-switch-event=${this.onAppLinkClicked}
                              entitlementList=${Main.doFilter(this._entitlements)}
                              _selectedAccountIdx=${_selectedAccountIdx}
                              _noAppsMessage=${_noAppsMessage}
                              selectedTenant=${selectedTenant}></cortex-app-switcher>`}
             </cortex-drawer>`
  }

  getAppSwitcherLabel() {
    if (this.appSwitcherLabel) {
      return html`<label>${this.appSwitcherLabel}</label>`
    }
  }
  render() {
    return html`
    <button class="cortex-app-switcher-icon" @click=${this._onAppSwitcherClick}>
         <div><img height="16" width="16" src=${Icons.APP_SWITCHER} />${this.getAppSwitcherLabel()}</div>
    </button>
    ${this.getAppSwitcher()}
    `
  }

}

customDefine(Main.is, Main)
