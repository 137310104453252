import { LitElement, html } from 'lit-element'
import LoadingStyle from './scss/loading.scss'
import { customDefine } from '../util'
/**
 *
 * Created by vsuthar on 2019-05-10 File loading
 * Project: App Portal ©PaloAlto Networks
 */
export default class Loading extends LitElement {


  static get is() {
    return 'cortex-loading'
  }
  /**
   *
   * @returns {{isLoading: {type: BooleanConstructor}}}
   */
  static get properties() {
    return {
      isLoading: { type: Boolean }
    }
  }

  /**
   *
   * @returns {*[]}
   */
  static get styles() {
    return [LoadingStyle]
  }

  /**
   *
   */
  constructor() {
    super()
    this.isLoading = false
  }

  /**
   *
   * @param isLoading
   */


  setLoading(isLoading) {
    this.isLoading = isLoading
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { isLoading } = this
    if (isLoading) {
      return html `
          <div class="cortex-loading"> 
            <div class="loader">
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
            </div>
          </div>
    `
    }
    else {
      return null
    }
  }
}

customDefine(Loading.is, Loading)
