/**
 *
 * Created by vsuthar on 2019-04-08 File Listitem
 * Project: App Portal ©PaloAlto Networks
 */
import { LitElement, html } from 'lit-element'
import ListItemStyle from './scss/listItem.scss'
import { customDefine } from '../util'

export default class ListItem extends LitElement {
  /**
   *
   * @returns {string}
   */
  static get is() {
    return 'cortex-list-item'
  }

  /**
   *
   * @returns {{label: {type: StringConstructor}, key: {type: StringConstructor}, selected: {type: BooleanConstructor}}}
   */
  static get properties() {
    return {
      label: { type: String },
      key: { type: String },
      selectedClass: { type: String },
      is_federal: { type: Boolean },
      theme: { type: String },
    }
  }

  /**
   *
   * @returns {*[]}
   */
  static get styles() {
    return [ListItemStyle]
  }

  /**
   *
   * @returns {TemplateResult|TemplateResult}
   */
  constructor() {
    super()
    this.selectedClass = ''
    this.theme = ''
  }



  render() {
    const { selectedClass, is_federal, theme } = this
    return html `
      <div><a class=${`cortex-list-item ${theme} ${selectedClass} ${is_federal ? 'item-is-federal' : ''}`}><slot></slot></a></div>

    `
  }
}

customDefine(ListItem.is, ListItem)
