/**
 *
 * Created by vsuthar on 2019-03-11 File DropDownMenu-css.js
 * Project: App Portal ©PaloAlto Networks
 */
import { css } from 'lit-element'
export const dropDownMenuStyle = css`
 .cortex-drop-down {
    display: flex;
    flex-direction: column;
    position:relative;
  }
 .cortex-drop-down button {
    transition: all 0.25s ease;
    opacity: .65;
    height:50px;
    border:none;
    background:transparent;
    color:#ffffff;
 }
 .cortex-drop-down button:hover {
    outline:none;
    opacity: 1;
    cursor: pointer;
    display:block;
 }
 .cortex-drop-down:hover .cortex-drop-down-content{
    display:block;
 }
 .cortex-drop-down-content{
    display:none;
    position:absolute;
    top:48px !important;
    z-index:1000;
    right: 10px;
    border-radius: 4px;
    clear: both;
    text-line: inherit;
    white-space: nowrap;
    background-color: transparent;
    border:0;
    background-color:#032737;
    font-size:0.89rem;
 }
 .cortex-drop-down-content a {
    color:#dadada;
    display:block;
    line-height: 16px;
    padding: 10px 20px;
    white-space: nowrap;
    text-decoration: none;
 }
 
 .cortex-drop-down-content a:hover, .cortex-drop-down-child-menu:hover{
    background-color:#04415C;
 }
 .cortex-drop-down-child-menu{
    color:#dadada;
    display:block;
    width:100%;
    white-space: nowrap;
    text-decoration: none;
 }
 .cortex-drop-down-child-menu > a{
    margin-left:10px;
 }

`
